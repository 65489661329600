import { useState } from "react";
import { ReactComponent as LogoIcon } from "../../assests/Logo.svg";
export default function Header() {
  const [height, setHeight] = useState();
  window.onscroll = function (e) {
    if (window.scrollY > 55) {
      setHeight(true);
    } else {
      setHeight(false);
    }
  };
  return (
    <>
      <header>
        <nav
          class="navbar navbar-expand-lg fixed-top navbar-scroll"
          style={{ backgroundColor: height ? "white" : "transparent" }}
        >
          <div class="container-fluid col-md-11 col-12 mx-auto">
            <a className="navbar-brand logoIcon" href="/">
              <LogoIcon width={"75%"} />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse head" id="navbarText">
              <ul className="navbar-nav ms-auto px-2 px-sm-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    About Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

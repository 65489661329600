// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCegVKcEjSKDqHsfGGT1Cf-zI1V6HAXCo0",
  authDomain: "fahm-qur-an.firebaseapp.com",
  projectId: "fahm-qur-an",
  storageBucket: "fahm-qur-an.appspot.com",
  messagingSenderId: "162969665242",
  appId: "1:162969665242:web:f3e52af75b70335d34afe3",
  // measurementId: "G-Z1E7DN6THB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
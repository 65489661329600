import "./App.css";
import Home from "./Containers/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import About from "./Containers/About/About";
import firebase from "./firebase";

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/Home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/" element={<Navigate to="/Home" replace />} />
        <Route path="*" element={<Navigate to="/Home" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import plan from "../../assests/plan.png";
import about from "../../assests/about.png";
import features from "../../assests/features.png";
import mobilepic from "../../assests/mobilepic.png";
import { ReactComponent as AppStoreIcon } from "../../assests/AppStore.svg";
import { ReactComponent as PlayStoreIcon } from "../../assests/PlayStore.svg";
import { ReactComponent as MobilePlayStoreIcon } from "../../assests/mobileplay.svg";
import { ReactComponent as MobileAppStoreIcon } from "../../assests/mobileapp.svg";
import { ReactComponent as CardIcon } from "../../assests/card.svg";
export default function Home() {
  return (
    <>
      <Header />
      <div className="homeHeader bg-image pt-5">
        <div className="row col-11 mx-auto align-items-center mt-5 pt-3">
          <div className="col-md-5 col-12 mx-auto mb-md-5 pb-md-4">
            <h1 className="heading">FAHM Qur’an</h1>
            <h5 className="text-muted">
              Enlighten yourself with the wisdom of Qur’an{" "}
            </h5>
            <h5>One Word At a Time</h5>
            <div className="icons">
              <div className="my-5 d-flex mx-2">
                <a
                  href="https://play.google.com/store/apps/details?id=com.globewyze.fahmquran"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PlayStoreIcon width={175} />
                </a>
                <a
                  href="https://apps.apple.com/pk/app/fahm-quran/id1564714230"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AppStoreIcon width={175} className="ms-3" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-12 mx-auto d-flex d-sm-block">
            <div className="iconsMobile">
              <div className="d-flex align-items-center mx-0">
                <div className="my-5 d-flex flex-column">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.globewyze.fahmquran"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MobilePlayStoreIcon className="my-3" />
                  </a>
                  <a
                    href="https://apps.apple.com/pk/app/fahm-quran/id1564714230"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MobileAppStoreIcon />
                  </a>
                </div>
                <img
                  src={mobilepic}
                  width={"80%"}
                  className="imgWidth"
                  alt=""
                />
              </div>
            </div>
            <img src={mobilepic} width={"100%"} className="imgMobile" alt="" />
          </div>
        </div>
      </div>
      <div className="banner bg-image pt-5">
        <div className="row mx-0 align-items-center mt-5">
          <div className="col-md-4 col-12 px-0">
            <img src={about} width={"80%"} alt="" />
          </div>
          <div className="col-lg-5 col-md-6 col-12 mx-md-0 mx-lg-auto mx-auto ">
            <p>
              <span className="fw-bold title">Fahm Qur’an</span> provides
              Muslims a simple way of searching, learning and comprehending most
              frequently used Qur’anic words by helping them understand its:
              <br />
              <ul className="ps-3">
                <li>Translation in contemporary English</li>
                <li>Root word</li>
                <li>Transliterations</li>
                <li>Root Word Variations</li>
                <li>Referenced Qur'anic Ayah and Commentary</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="mt-5 col-md-10 col-12 mx-auto">
          <h3 className="text-white text-center mb-0 pt-lg-5 mt-lg-2">Feature Set</h3>
          <p className="col-md-10 col-12 mx-auto mb-0">
            <img src={features} alt="" width={"100%"} />
          </p>
        </div>
      </div>
      <div className="mx-auto col-md-9 col-11 py-5">
        <h4 className="fw-bold text-center">
          Your Contribution can make a Significant Impact.
        </h4>
        <p className="text-center">
          Be part of Fahm Qur’an’s journey in spreading the wisdom
          <br /> of the Holy Qur’an.
        </p>
        <div className="row mx-0 align-items-center">
          <div className="col-md-6 col-12 mx-auto">
            <div className="card border-0 rounded shadow col-lg-7 col-md-10 my-3">
              <div className="card-body">
                <h5 className="card-title text-center">
                  <CardIcon />
                </h5>
                <h6 className="text-center">Premium</h6>
                <h2 className="text-center fw-bold">$1</h2>
                <div className="d-flex justify-content-center">
                  <ul>
                    <li className="pb-3">Access to entire library</li>
                    <li>Unlimited word search</li>
                  </ul>
                </div>
                <h6 className="text-center pt-5" style={{ color: "#F47B5C" }}>
                  Your contribution can make a significant impact.
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mx-auto">
            <img src={plan} alt="" width={"80%"} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

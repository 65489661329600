import { ReactComponent as LogoIcon } from "../../assests/Logo.svg";
import { ReactComponent as AppStoreIcon } from "../../assests/AppStore.svg";
import { ReactComponent as PlayStoreIcon } from "../../assests/PlayStore.svg";
import { ReactComponent as FacebookIcon } from "../../assests/facebook.svg";
import { ReactComponent as MobilePlayStoreIcon } from "../../assests/mobileplay.svg";
import { ReactComponent as MobileAppStoreIcon } from "../../assests/mobileapp.svg";

export default function Footer() {
  return (
    <>
      <footer className="text-white text-center text-lg-start">
        <div className="container p-4">
          <LogoIcon />
          <hr />

          <div className="row mt-5">
            <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
              <p className=" mb-4">Ready to get started?</p>
              <div className="iconsMobile">
                <div className="d-flex flex-column">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.globewyze.fahmquran"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MobilePlayStoreIcon className="my-3" />
                  </a>
                  <a
                    href="https://apps.apple.com/pk/app/fahm-quran/id1564714230"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MobileAppStoreIcon />
                  </a>
              </div>
            </div>
              <p className="icons">
                <a
                  href="https://apps.apple.com/pk/app/fahm-quran/id1564714230"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AppStoreIcon width={175} />
                </a>
              </p>

              <p className="icons">
                <a
                  href="https://play.google.com/store/apps/details?id=com.globewyze.fahmquran"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PlayStoreIcon width={175} />
                </a>
              </p>
            </div>
            <div className="col-lg-6 col-md-12 mb-4 mb-md-0 row">
              <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 style={{ color: "#FD6139" }}>Features</h5>
                <p>Flexible Search</p>
                <p>Ayah Reference</p>
                <p>Shareable Words</p>
                <p>Offline</p>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 style={{ color: "#FD6139" }}>About</h5>
                <a className="nav-link" href="/about">
                    About
                  </a>
                {/* <p></p> */}
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 style={{ color: "#FD6139" }}>Help</h5>
                <p>
                  <a
                    href="mailto:sales@globewyze.com"
                    className="text-white text-decoration-none"
                  >
                    Contact Us
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-10 mx-auto col-11 align-items-center">
          <div className="col-lg-6 col-md-12 mx-auto">
            <div className="d-flex justify-content-center justify-content-lg-start">
              <h6 className="text-white">Terms & Conditions</h6>
              <h6 className="text-white ms-5">Privacy Policy</h6>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 d-flex justify-content-end mt-lg-0 mt-2">
            <div className="col-6 mx-lg-0 mx-auto">
              <a
                href="https://www.facebook.com/profile.php?id=100086572741287"
                target="_blank"
                rel="noreferrer"
                className="text-white text-decoration-none"
              >
                <FacebookIcon width={150} />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center mt-5">
          <span style={{ color: "#DDDDDD", fontSize: "small" }}>
            Powered by{" "}
          </span>
          <span className="text-white">
            {" "}
            <a
              href="https://globewyze.com/"
              target="_blank"
              rel="noreferrer"
              className="text-white text-decoration-none"
            >
              Globewyze Inc
            </a>{" "}
          </span>
        </div>
        <div
          className="text-center pb-3"
          style={{ color: "#DDDDDD", fontSize: "small" }}
        >
          Copyright © Wyzerlink Inc. All rights reserved.
        </div>
      </footer>
    </>
  );
}

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import mobilepic from "../../assests/mobilepic.png";
export default function About() {
  return (
    <>
      <Header />
      <div className="aboutHeader bg-image pt-5 pt-xl-3">
        <div className="row col-11 mx-auto align-items-center pt-5 pt-lg-0">
          <div className="col-md-5 col-12 mx-auto mb-md-5 pb-md-4">
            <h2>About </h2>
            <h1 style={{ fontFamily: "Quicksand" }}>FAHM Qur’an</h1>
          </div>
          <div className="col-md-5 col-12 mx-auto mt-4">
            <img src={mobilepic} width={"90%"} alt="" />
          </div>
        </div>
      </div>
      <div
        className="col-sm-6 col-9 mx-auto my-4 py-5"
        style={{ textAlign: "justify" }}
      >
        {/* <div className="col-lg-9 col-xl-9 col-md-7"> */}
        <p className="">
          <span className="fw-bold" style={{ color: "#fe5f39" }}>
            Fahm Qur’an
          </span>{" "}
          provides Muslims a simple way of searching, learning and comprehending
          most frequently used Qur’anic words.
        </p>
        <p>
          Each word in the Qur’an holds multiple layers of wisdom. Fahm,
          developed by WyzerLink; enable readers to transfer this wisdom into
          their daily lives and helps users become familiar with frequently used
          words in the Qur’an by understanding its:
        </p>
        <p className="mb-0">Translation in contemporary English</p>
        <p className="mb-0">Root word</p>
        <p className="mb-0">Transliterations</p>
        <p>Root Word Variations</p>
        <p>Supported by reference Qur’anic Ayah and commentary.</p>
        {/* </div> */}
        {/* <div className="col-md-6 mx-auto py-4"> */}
        <h4 className="text-center fw-bold pt-5">
          We pray to Allah (SWT) to reward everyone who has contributed into
          Fahm as a user and developer
        </h4>
        {/* </div> */}
      </div>

      <Footer />
    </>
  );
}
